import axios from 'axios'
import options from './options'
import bffOptions from './bffOptions'

const axiosClient = axios.create(options)
const axiosAuth = axios.create(bffOptions)

// A flag to prevent multiple token refresh attempts at the same time
let isRefreshing = false
let failedRequestsQueue = []

axiosClient.interceptors.response.use(
  function (response) {
    return response
  },
  async function (error) {
    const originalRequest = error.config

    // Check if the error is a 403 Forbidden response
    if (
      error.response &&
      error.response.status === 403 &&
      !originalRequest._retry
    ) {
      // Set a retry flag to prevent an infinite loop
      originalRequest._retry = true

      // Check if a token refresh is already in progress
      if (!isRefreshing) {
        isRefreshing = true

        try {
          // Call the BFF to refresh the token
          const { data: accessToken } = await axiosAuth.get('/refresh')

          // Update the token in the request headers (if needed)
          axiosAuth.defaults.headers.Authorization = `Bearer ${accessToken}`

          // Retry all failed requests with the new token
          failedRequestsQueue.forEach((req) => req.resolve(accessToken))
          failedRequestsQueue = []

          // Retry the original request with the new token
          originalRequest.headers.Authorization = `Bearer ${accessToken}`
          return axiosAuth(originalRequest)
        } catch (err) {
          // Reject all failed requests if token refresh failed
          failedRequestsQueue.forEach((req) => req.reject(err))
          failedRequestsQueue = []

          // Handle token refresh failure (e.g., log the user out)
          return Promise.reject(err)
        } finally {
          isRefreshing = false
        }
      }

      // Queue the failed requests while the token refresh is in progress
      return new Promise((resolve, reject) => {
        failedRequestsQueue.push({
          resolve: (token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`
            resolve(axiosAuth(originalRequest))
          },
          reject: (err) => {
            reject(err)
          }
        })
      })
    }

    // If not a 403 error, or a retry, reject the promise as usual
    return Promise.reject(error)
  }
)

function install (Vue) {
  Vue.axios = axiosClient
  Vue.axiosAuth = axiosAuth
  window.axios = axiosClient
  window.axiosAuth = axiosAuth
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return axiosClient
      }
    },
    axiosAuth: {
      get () {
        return axiosAuth
      }
    },
    $axios: {
      get () {
        return axiosClient
      }
    },
    $axiosAuth: {
      get () {
        return axiosAuth
      }
    }
  })
}

export { axiosClient, axiosAuth }
export default install
