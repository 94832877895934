<template>
  <v-app>
    <AppBar :smallScreen="smallScreen" @menu="menu = !menu" />
    <CaseInboxDrawer v-model="menu" v-if="!isBlockLoading && !error && isAuthUser" />
    <v-main>
      <v-container fluid v-if="!isBlockLoading && (isError || error)" fill-height>
        <v-row>
          <v-col class="my-5 text-center">
            <v-icon x-large color="error">mdi-alert</v-icon>
            <p class="my-5">
              {{ i18n("generalErrorMessage") }}
            </p>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-if="isBlockLoading" fill-height>
        <v-row>
          <v-col class="my-5 text-center">
            <v-progress-circular
              indeterminate
              size="128"
              color="primary">
              {{ i18n("loading") }}
            </v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
      <v-container fill-height v-if="currentView === 'startLogin' && !isBlockLoading && !error && !isAuthUser">
        <v-row>
          <v-col cols="12" class="text-center">
            <h2>{{ i18n("pleaseLogin") }}</h2>
          </v-col>
          <v-col cols="12" class="text-center">
            <LoginButton />
          </v-col>
        </v-row>
      </v-container>
      <v-container fill-height v-if="currentView === 'caseInbox' && !isBlockLoading && !error && isAuthUser">
        <v-row>
          <v-col class="text-center">
            <h3 class="text--secondary">
              {{ i18n("selectACase") }}
            </h3>
            <v-icon x-large class="my-5">mdi-menu</v-icon>
          </v-col>
        </v-row>
      </v-container>
      <v-container v-if="currentView === 'job'">
        <v-container fluid v-if="!isBlockLoading && !isActiveJob && !isError">
          <v-row>
            <v-col
              :cols="smallScreen ? 12 : 4"
              class="text-center mx-auto">
              <v-card outlined class="rounded-lg">
                <v-app-bar
                  dense
                  dark
                  color="success"
                  elevation="0"
                  class="mb-5">
                  <v-toolbar-title class="mx-auto">
                      {{ i18n("jobStatus") }}
                  </v-toolbar-title>
                </v-app-bar>
                <v-icon x-large color="success">mdi-check-circle</v-icon>
                <v-card-text>
                  {{ i18n("jobCompleted") }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="currentView === 'job' && !isBlockLoading && !error && isActiveJob" fluid>
          <v-row v-if="!smallScreen || (smallScreen && tab == 'overview')">
            <v-col class="pb-0">
              <ProgressBar />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :cols="smallScreen ? '12' : '4'"
              v-if="!smallScreen || (smallScreen && tab !== 'chat')"
              class="pb-0">
              <Overview v-if="isSmallScreen('overview')" class="mb-4" />
              <Start v-if="isSmallScreen('overview')" class="mb-4" />
              <JobFiles v-if="isSmallScreen('overview') && kase.status === 'IN_PROGRESS'"/>
            </v-col>
            <v-col
              :cols="smallScreen ? '12' : '4'"
              v-if="isSmallScreen('chat')">
              <Chat :unreadMessage="unreadMessage" :smallScreen="smallScreen" v-if="kase.status === 'IN_PROGRESS'"/>
            </v-col>
            <v-col
              :cols="smallScreen ? '12' : '4'"
              v-if="isSmallScreen('files')"
              class="pb-0">
              <DigitalJob :smallScreen="smallScreen" class="mb-2" v-if="kase.status === 'IN_PROGRESS'"/>
              <PhysicalJob :smallScreen="smallScreen" class="mb-2" v-if="kase.status === 'IN_PROGRESS'" />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </v-main>
    <v-bottom-navigation
      v-if="currentView === 'job' && smallScreen && isActiveJob && kase.status === 'IN_PROGRESS'"
      background-color="white"
      light
      app
      v-model="tab"
      grow
      color="primary">
      <v-btn value="overview" icon>
        <span>{{ i18n("jobOverview") }}</span>
        <v-icon>mdi-briefcase</v-icon>
      </v-btn>
      <v-btn value="chat" icon>
        <span>{{ i18n("messages") }}</span>
        <v-badge overlap color="error" :value="unreadMessage > 0">
          <template v-slot:badge>
            <small>{{ unreadMessage }}</small>
          </template>
          <v-icon>mdi-message</v-icon>
        </v-badge>
      </v-btn>
      <v-btn value="files" icon>
        <span>{{ i18n("fileCompletion") }}</span>
        <v-icon>mdi-file-check</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LoginButton from '@/components/LoginButton'
import AppBar from '@/components/AppBar'
import CaseInboxDrawer from '@/components/CaseInboxDrawer'
import Overview from '@/components/Overview'
import JobFiles from '@/components/JobFiles'
import DigitalJob from '@/components/DigitalJob'
import PhysicalJob from '@/components/PhysicalJob'
import Start from '@/components/Start'
import Chat from '@/shared/Chat'
import ProgressBar from '@/components/ProgressBar'

export default {
  name: 'App',
  components: {
    AppBar,
    CaseInboxDrawer,
    LoginButton,
    Overview,
    JobFiles,
    DigitalJob,
    PhysicalJob,
    Start,
    Chat,
    ProgressBar
  },
  data () {
    return {
      intervalID: null,
      error: false,
      jobType: null,
      menuToggle: false
    }
  },
  computed: {
    ...mapGetters([
      'currentView',
      'kase',
      'isBlockLoading',
      'isError',
      'isAuthUser',
      'currentTab'
    ]),
    menu: {
      get () {
        if (!this.smallScreen) { return true }
        return this.menuToggle
      },
      set (value) {
        this.menuToggle = value
      }
    },
    tab: {
      get () {
        return this.currentTab
      },
      set (value) {
        this.setCurrentTab(value)
      }
    },
    smallScreen () {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
    },
    isActiveJob () {
      return this.kase && !this.kase.marked_as_completed && !this.kase.end
    },
    unreadMessage () {
      if (!this.kase) { return 0 }
      if (!this.kase.messages) { return 0 }
      return this.kase.messages.filter(message => !message.message_seen).length
    }
  },
  methods: {
    ...mapActions(['setCurrentTab']),
    isSmallScreen (tab) {
      return !this.smallScreen || (this.smallScreen && this.tab === tab)
    }
  }
}
</script>
