<template>
  <v-footer
    :app="smallScreen"
    rounded="0"
    :class="{'pa-2': true, 'rounded-b-lg': !smallScreen}"
    v-if="kase">
    <v-row>
      <v-col
        cols="12"
        v-if="loading"
        class="text-center">
        {{ i18n("sending") }}
        <v-progress-linear
          indeterminate
          class="my-3"
          color="primary" />
      </v-col>
      <v-col
        cols="12"
        v-if="!loading">
        <v-textarea
          hide-details
          v-model="message"
          :placeholder="i18n('message')"
          background-color="white"
          light
          solo
          flat
          auto-grow
          rows="1">
          <template v-slot:append>
            <v-file-input
              v-model="attachments"
              hide-input
              multiple
              class="message-attachment pa-0 ma-0" />
            <v-tooltip
              left
              :disabled="validation === true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="send()">
                  <v-icon>mdi-send</v-icon>
                </v-btn>
              </template>
              <div v-if="!(validation === true)">
                <ul>
                  <li
                    v-for="(error, index) in validation"
                    :key="index">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </v-tooltip>
          </template>
        </v-textarea>
        <v-chip
          small
          v-for="(attachment, index) in attachments"
          :key="index"
          close
          class="py-1 my-1 mr-2"
          @click:close="removeAttachment(attachment)">
          <v-icon>mdi-paperclip</v-icon>
          {{ attachment.name }}
        </v-chip>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import options from '@/api/options'

export default {
  name: 'Compose',
  data () {
    return {
      loading: false,
      message: null,
      attachments: []
    }
  },
  computed: {
    ...mapGetters(['kase']),
    attachmentsInput: {
      get () {
        return this.attachments
      },
      set (value) {
        value.forEach(attachment => {
          if (this.attachments.map(existing => existing.name).includes(attachment.name)) { return }
          this.attachments.push(attachment)
        })
      }
    },
    messagePayload () {
      return {
        outsource_uid: this.kase.uid,
        case_uid: this.kase.case_uid,
        message_type: 'OUTSOURCE_MESSAGE',
        author_reference: this.kase.partner_name,
        direction: 'INBOUND',
        note: this.message
      }
    },
    validation () {
      const errors = []
      if (!this.messagePayload.note || (this.messagePayload.note && this.messagePayload.note.trim().length === 0)) {
        errors.push(this.i18n('supplyMessage'))
      }
      return errors.length > 0 ? errors : true
    },
    smallScreen () {
      return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm'
    }
  },
  methods: {
    ...mapActions(['startLoading', 'stopLoading', 'triggerReload']),
    removeAttachment (attachment) {
      const index = this.attachments.indexOf(attachment)
      if (index < 0) { return }
      this.attachments.splice(index, 1)
    },
    async handleFileUpload (file) {
      try {
        const oftResponse = await this.oftAddFile(file)
        const { oneTimeUploadLink, fileName } = oftResponse.data[0]
        await this.uploadToBucket(oneTimeUploadLink, file)
        return {
          file_name: fileName
        }
      } catch (error) {
        console.error('Error in handleFileUpload:', error)
        throw error
      }
    },
    async oftAddFile (file) {
      try {
        const payload = [{
          fileName: file.name
        }]
        const response = await this.$axios.post(`${options.oftURL}api/v1/files/upload`, payload)
        return response
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    async uploadToBucket (url, file) {
      try {
        const uploadResponse = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type
          },
          body: file
        })

        if (uploadResponse.ok) {
          this.message = 'File uploaded successfully!'
        } else {
          this.message = 'File upload failed.'
        }
      } catch (error) {
        console.error('Error uploading file:', error)
        throw error
      }
    },
    async send () {
      if (!(this.validation === true)) { return }
      this.loading = true
      this.startLoading()
      const formData = new FormData()
      for (var key in this.messagePayload) {
        if (this.messagePayload[key]) {
          formData.append(key, this.messagePayload[key])
        }
      }
      const uploads = []
      this.attachments.forEach(attachment => {
        uploads.push(this.handleFileUpload(attachment))
      })
      const uploadResults = await Promise.all(uploads)
      uploadResults.forEach(result => formData.append('oft_attachments', result.file_name))

      this.$axios.post(`/api/v2/outsource/${this.kase.outsource_key}/message/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        this.triggerReload()
        this.stopLoading()
        this.reset()
      })
    },
    reset () {
      this.message = null
      this.attachments = []
      this.$emit('sent', true)
      this.loading = false
    }
  }
}
</script>

<style>
.message-attachment {
  display: inline !important;
}
.message-attachment > .v-input__append-outer, .v-input__prepend-outer {
  display: inline !important;
}
</style>
