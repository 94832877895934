<template>
  <v-app-bar app dark clipped-left elevation="1">
    <v-app-bar-nav-icon v-if="smallScreen" @click="openMenu()">
      <v-btn icon>
        <v-icon large>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar-nav-icon>
    <v-app-bar-nav-icon class="ma-1">
      <v-img contain height="45" width="45" :src="require('@/assets/osteon-logo.png')" />
    </v-app-bar-nav-icon>
    <v-toolbar-title>
      <h3>{{ i18n("partnerPlatform") }}</h3>
    </v-toolbar-title>
    <v-spacer />
    <LoginButton v-if="currentView !== 'startLogin' && !smallScreen" />
    <v-menu
      open-on-hover
      left
      offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="rounded-lg"
          elevation="0"
          v-bind="attrs"
          v-on="on">
          <v-icon>mdi-translate</v-icon>
          <v-icon v-if="!smallScreen">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-card class="mx-auto rounded-lg">
        <v-list-item-group
          v-model="selectedLanguage">
          <v-list nav dense>
            <v-subheader class="text--primary font-weight-black text-uppercase">
              {{ i18n("translations") }}
            </v-subheader>
            <v-list-item
              v-for="(language, index) in languages"
              :key="index"
              @click="selectedLanguage = index">
              <v-list-item-title>{{ language.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-list-item-group>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import fetchURLParam from '@/shared/url_params'
import LoginButton from '@/components/LoginButton'

export default {
  name: 'AppBar',
  components: {
    LoginButton
  },
  props: ['smallScreen'],
  data () {
    return {
      fetching: false,
      intervalID: null,
      fetchUpdateMS: 10000
    }
  },
  watch: {
    reloadTrigger (value) {
      if (value) {
        this.fetchData()
        this.reloadComplete()
      }
    }
  },
  computed: {
    ...mapGetters([
      'isAuthUser',
      'outsourceKey',
      'currentView',
      'reloadTrigger',
      'locale',
      'languages',
      'selectedItem'
    ]),
    selectedLanguage: {
      get () {
        return this.selectedItem
      },
      set (value) {
        this.updateLocalStorage(value)
        this.setLocale()
        this.setTabTitle()
        this.triggerReload()
      }
    }
  },
  methods: {
    ...mapActions([
      'setAccessToken',
      'setCurrentView',
      'setOutsourceKey',
      'startLoading',
      'stopLoading',
      'startBlockLoading',
      'stopBlockLoading',
      'setError',
      'reloadComplete',
      'triggerReload',
      'setKase',
      'setLocale',
      'updateLocalStorage',
      'setMessages',
      'setIsAuthUser'
    ]),
    fetchParams () {
      this.setOutsourceKey(fetchURLParam('key'))
    },
    async fetchMe () {
      return new Promise((resolve) => {
        this.$axiosAuth.get('/me', { withCredentials: true })
          .then(response => {
            const accessToken = response.data.token.access_token
            const isAuthUser = response.data.is_auth_user
            this.setIsAuthUser(isAuthUser)
            this.setAccessToken(accessToken)
            if (isAuthUser) {
              this.$axios.defaults.headers.Authorization = `Bearer ${accessToken}`
            }
            resolve()
          })
          .catch(() => {
            this.setError()
            this.stopBlockLoading()
          })
      })
    },
    fetchData () {
      return new Promise((resolve) => {
        if (!this.outsourceKey) { return resolve() }
        if (this.fetching) { return resolve() }
        this.fetching = true
        this.startLoading()
        const requests = []
        requests.push(this.fetchJob())
        requests.push(this.fetchMessages())
        requests.push(this.fetchMe())

        Promise.all(requests).then(() => {
          resolve()
          this.fetching = false
          this.stopLoading()
        })
      })
    },
    async fetchJob () {
      return new Promise((resolve) => {
        this.$axios.get(`/api/v2/outsource/${this.outsourceKey}/`).then(response => {
          if (
            response.data &&
            (response.data.marked_as_completed ||
            response.data.end)
          ) {
            clearInterval(this.intervalID)
          }
          this.setKase(response.data)
          resolve()
        }).catch(() => {
          this.setError()
          this.stopBlockLoading()
        })
      })
    },
    async fetchMessages () {
      return new Promise((resolve, reject) => {
        this.$axios
          .get(`/api/v2/outsource/${this.outsourceKey}/messages/`)
          .then((response) => {
            this.setMessages(response.data)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    setTabTitle () {
      document.title = this.i18n('tabTitle')
    },
    openMenu () {
      this.$emit('menu')
    }
  },
  mounted () {
    this.setLocale()
    this.setTabTitle()
    this.startBlockLoading()
    this.fetchParams()
    this.fetchMe().then(() => {
      if (!this.outsourceKey && !this.isAuthUser) {
        this.setCurrentView('startLogin')
        this.stopBlockLoading()
        return
      }
      if (this.isAuthUser) {
        this.setCurrentView('caseInbox')
      }
      if (this.outsourceKey) {
        this.setCurrentView('job')
      }
    })
    this.fetchData().then(() => {
      this.stopBlockLoading()
    })
    this.intervalID = setInterval(this.fetchData, this.fetchUpdateMS)
  },
  beforeUnmount () {
    clearInterval(this.intervalID)
  }
}
</script>
