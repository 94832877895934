<template>
  <v-card outlined rounded="lg" v-if="!kase.upload_not_required">
    <v-app-bar dense color="grey lighten-2" elevation="0" class="mb-2">
      <v-toolbar-title>
        <v-icon class="mr-2"> mdi-cloud-check </v-icon>
        {{ i18n("completedDigitalWorkSection") }}
      </v-toolbar-title>
      <v-spacer />
      <v-chip color="warning" class="rounded-lg">{{ i18n("digital") }} </v-chip>
    </v-app-bar>
    <v-card-text>
      <v-file-input
        outlined
        dense
        v-model="upload"
        :label="i18n('uploadFileLabel')"
      />
      <v-alert v-if="errorMessage" type="error" dense outlined class="mt-2">
        {{ errorMessage }}
      </v-alert>
      <v-row>
        <v-spacer />
        <v-col :cols="smallScreen ? 12 : 6">
          <v-btn
            class="rounded-lg"
            color="success"
            width="100%"
            :disabled="uploading || !upload"
            @click="uploadAndComplete()"
          >
            <v-progress-circular
              class="mr-2"
              v-if="uploading"
              size="24"
              indeterminate
            />
            <v-icon class="mr-2" v-if="!uploading"> mdi-cloud-upload </v-icon>
            {{ uploading ? i18n("uploading") : i18n("upload") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import options from '@/api/options'

export default {
  name: 'DigitalJob',
  props: ['smallScreen'],
  data () {
    return {
      upload: null,
      uploading: false,
      fileName: null,
      uploadUrl: null,
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters(['kase'])
  },
  methods: {
    ...mapActions(['triggerReload']),
    async requestUploadLink () {
      try {
        const response = await this.$axios.post(
          `${options.oftURL}api/v1/files/upload`,
          [
            {
              fileName: this.upload.name,
              fileReferences: [
                {
                  entityType: 'CASE',
                  entityIdentification: this.kase.case_uid
                },
                {
                  entityType: 'OUTSOURCE',
                  entityIdentification: this.kase.uid
                }
              ]
            }
          ]
        )
        this.uploadUrl = response.data[0].oneTimeUploadLink
        this.fileName = response.data[0].fileName
        this.errorMessage = ''
      } catch (error) {
        console.error('Error requesting upload link:', error)
        if (error.response && error.response.status < 500) {
          this.errorMessage = error.response?.data?.message
        } else {
          this.errorMessage =
            'An error occurred. Please try again later or contact us.'
          throw error
        }
      }
    },
    async uploadToBucket (url, file) {
      try {
        const uploadResponse = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type
          },
          body: file
        })

        if (!uploadResponse.ok) {
          throw new Error('Upload failed.')
        }
      } catch (error) {
        this.errorMessage =
            'An error occurred. Please try again later or contact us.'
        throw error
      }
    },
    async completeJob () {
      try {
        const formData = new FormData()
        formData.append('action', 'complete_job')
        formData.append('file_name', this.fileName)
        const response = await this.$axios.post(
          `/api/v2/outsource/${this.kase.outsource_key}/`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        return response.data
      } catch (error) {
        this.errorMessage =
            'An error occurred. Please try again later or contact us.'
        throw error
      }
    },
    async uploadAndComplete () {
      this.uploading = true
      try {
        await this.requestUploadLink()
        await this.uploadToBucket(this.uploadUrl, this.upload)
        await this.completeJob()
      } catch (error) {
        console.error('Error in upload and complete process:', error)
      } finally {
        this.uploading = false
        this.upload = null
        this.triggerReload()
      }
    }
  }
}
</script>
