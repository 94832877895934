<template>
  <span>
    <v-btn color="primary" @click="startLogin" v-if="!isAuthUser">
      <v-icon class="mr-2">mdi-account</v-icon>
      {{ i18n("login") }}
    </v-btn>
    <v-btn color="error" @click="startLogout" v-if="isAuthUser">
      <v-icon class="mr-2">mdi-logout</v-icon>
      {{ i18n("logout") }}
    </v-btn>
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoginButton',
  computed: {
    ...mapGetters(['isAuthUser'])
  },
  methods: {
    startLogin () {
      window.location.href = '/authorize'
    },
    startLogout () {
      window.location.href = '/logout'
    }
  }
}
</script>
